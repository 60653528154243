// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nabizime-arboritec-js": () => import("./../../../src/pages/nabizime/arboritec.js" /* webpackChunkName: "component---src-pages-nabizime-arboritec-js" */),
  "component---src-pages-nabizime-index-js": () => import("./../../../src/pages/nabizime/index.js" /* webpackChunkName: "component---src-pages-nabizime-index-js" */),
  "component---src-pages-nabizime-nanotechnologie-js": () => import("./../../../src/pages/nabizime/nanotechnologie.js" /* webpackChunkName: "component---src-pages-nabizime-nanotechnologie-js" */),
  "component---src-pages-nabizime-verinlegno-js": () => import("./../../../src/pages/nabizime/verinlegno.js" /* webpackChunkName: "component---src-pages-nabizime-verinlegno-js" */),
  "component---src-pages-nabizime-vzorovani-js": () => import("./../../../src/pages/nabizime/vzorovani.js" /* webpackChunkName: "component---src-pages-nabizime-vzorovani-js" */),
  "component---src-pages-nase-vize-js": () => import("./../../../src/pages/nase-vize.js" /* webpackChunkName: "component---src-pages-nase-vize-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */)
}

